import React from "react";
import { Navigate, useParams } from "react-router";
import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";

const ActiveBoard = ({ children }) => {
  const { board } = useParams();
  const current_board = localStorage.getItem("current_board");
  return (
    <Ternary
      when={board <= current_board}
      then={children}
      otherwise={<Navigate to="404" replace />}
    />
  );
};

export default ActiveBoard;
